
    <template>
      <section class="content element-doc">
        <h2>Avatar 头像</h2>
<p>用图标、图片或者字符的形式展示用户或事物信息。</p>
<h3>基本用法</h3>
<p>通过 <code>shape</code> 和 <code>size</code> 设置头像的形状和大小。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-row class=&quot;demo-avatar demo-basic&quot;&gt;
    &lt;el-col :span=&quot;12&quot;&gt;
      &lt;div class=&quot;sub-title&quot;&gt;circle&lt;/div&gt;
      &lt;div class=&quot;demo-basic--circle&quot;&gt;
        &lt;div class=&quot;block&quot;&gt;
          &lt;el-avatar :size=&quot;50&quot; :src=&quot;circleUrl&quot;&gt;&lt;/el-avatar&gt;
        &lt;/div&gt;
        &lt;div class=&quot;block&quot; v-for=&quot;size in sizeList&quot; :key=&quot;size&quot;&gt;
          &lt;el-avatar :size=&quot;size&quot; :src=&quot;circleUrl&quot;&gt;&lt;/el-avatar&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/el-col&gt;
    &lt;el-col :span=&quot;12&quot;&gt;
      &lt;div class=&quot;sub-title&quot;&gt;square&lt;/div&gt;
      &lt;div class=&quot;demo-basic--circle&quot;&gt;
        &lt;div class=&quot;block&quot;&gt;
          &lt;el-avatar shape=&quot;square&quot; :size=&quot;50&quot; :src=&quot;squareUrl&quot;&gt;&lt;/el-avatar&gt;
        &lt;/div&gt;
        &lt;div class=&quot;block&quot; v-for=&quot;size in sizeList&quot; :key=&quot;size&quot;&gt;
          &lt;el-avatar shape=&quot;square&quot; :size=&quot;size&quot; :src=&quot;squareUrl&quot;&gt;&lt;/el-avatar&gt;
        &lt;/div&gt;
      &lt;/div&gt;
    &lt;/el-col&gt;
  &lt;/el-row&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      let data = reactive({
        circleUrl:
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        squareUrl:
          'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
        sizeList: ['large', 'medium', 'small']
      })
      return { ...toRefs(data) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>展示类型</h3>
<p>支持三种类型：图标、图片和字符</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-type&quot;&gt;
    &lt;div&gt;
      &lt;el-avatar icon=&quot;el-icon-user-solid&quot;&gt;&lt;/el-avatar&gt;
    &lt;/div&gt;
    &lt;div&gt;
      &lt;el-avatar
        src=&quot;https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png&quot;
      &gt;&lt;/el-avatar&gt;
    &lt;/div&gt;
    &lt;div&gt;
      &lt;el-avatar&gt; user &lt;/el-avatar&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
</code></pre></template></demo-block><h3>图片加载失败的 fallback 行为</h3>
<p>当展示类型为图片的时候，图片加载失败的 fallback 行为</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-type&quot;&gt;
    &lt;el-avatar :size=&quot;60&quot; src=&quot;https://empty&quot; @error=&quot;errorHandler&quot;&gt;
      &lt;img
        src=&quot;https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png&quot;
      /&gt;
    &lt;/el-avatar&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      const errorHandler = () =&gt; {
        return true
      }
      return {
        errorHandler
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>图片如何适应容器框</h3>
<p>当展示类型为图片的时候，使用 <code>fit</code> 属性定义图片如何适应容器框，同原生 <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit">object-fit</a>。</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;demo-fit&quot;&gt;
    &lt;div class=&quot;block&quot; v-for=&quot;fit in fits&quot; :key=&quot;fit&quot;&gt;
      &lt;span class=&quot;title&quot;&gt;{{ fit }}&lt;/span&gt;
      &lt;el-avatar shape=&quot;square&quot; :size=&quot;100&quot; :fit=&quot;fit&quot; :src=&quot;url&quot;&gt;&lt;/el-avatar&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      let data = reactive({
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      })
      return { ...toRefs(data) }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>icon</td>
<td>设置头像的图标类型，参考 Icon 组件</td>
<td>string</td>
<td></td>
<td></td>
</tr>
<tr>
<td>size</td>
<td>设置头像的大小</td>
<td>number/string</td>
<td>number / large / medium / small</td>
<td>large</td>
</tr>
<tr>
<td>shape</td>
<td>设置头像的形状</td>
<td>string</td>
<td>circle / square</td>
<td>circle</td>
</tr>
<tr>
<td>src</td>
<td>图片头像的资源地址</td>
<td>string</td>
<td></td>
<td></td>
</tr>
<tr>
<td>srcSet</td>
<td>以逗号分隔的一个或多个字符串列表表明一系列用户代理使用的可能的图像</td>
<td>string</td>
<td></td>
<td></td>
</tr>
<tr>
<td>alt</td>
<td>描述图像的替换文本</td>
<td>string</td>
<td></td>
<td></td>
</tr>
<tr>
<td>fit</td>
<td>当展示类型为图片的时候，设置图片如何适应容器框</td>
<td>string</td>
<td>fill / contain / cover / none / scale-down</td>
<td>cover</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>error</td>
<td>图片类头像加载失败的回调， 返回 false 会关闭组件默认的 fallback 行为</td>
<td>(e: Event)</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>名称</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>default</td>
<td>自定义头像展示内容</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_avatar = _resolveComponent("el-avatar")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, { class: "demo-avatar demo-basic" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 12 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "sub-title" }, "circle"),
          _createVNode("div", { class: "demo-basic--circle" }, [
            _createVNode("div", { class: "block" }, [
              _createVNode(_component_el_avatar, {
                size: 50,
                src: _ctx.circleUrl
              }, null, 8, ["src"])
            ]),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sizeList, (size) => {
              return (_openBlock(), _createBlock("div", {
                class: "block",
                key: size
              }, [
                _createVNode(_component_el_avatar, {
                  size: size,
                  src: _ctx.circleUrl
                }, null, 8, ["size", "src"])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 12 }, {
        default: _withCtx(() => [
          _createVNode("div", { class: "sub-title" }, "square"),
          _createVNode("div", { class: "demo-basic--circle" }, [
            _createVNode("div", { class: "block" }, [
              _createVNode(_component_el_avatar, {
                shape: "square",
                size: 50,
                src: _ctx.squareUrl
              }, null, 8, ["src"])
            ]),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.sizeList, (size) => {
              return (_openBlock(), _createBlock("div", {
                class: "block",
                key: size
              }, [
                _createVNode(_component_el_avatar, {
                  shape: "square",
                  size: size,
                  src: _ctx.squareUrl
                }, null, 8, ["size", "src"])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      let data = reactive({
        circleUrl:
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        squareUrl:
          'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
        sizeList: ['large', 'medium', 'small']
      })
      return { ...toRefs(data) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, createTextVNode: _createTextVNode, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createBlock("div", { class: "demo-type" }, [
    _createVNode("div", null, [
      _createVNode(_component_el_avatar, { icon: "el-icon-user-solid" })
    ]),
    _createVNode("div", null, [
      _createVNode(_component_el_avatar, { src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" })
    ]),
    _createVNode("div", null, [
      _createVNode(_component_el_avatar, null, {
        default: _withCtx(() => [
          _createTextVNode(" user ")
        ]),
        _: 1
      })
    ])
  ]))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createBlock("div", { class: "demo-type" }, [
    _createVNode(_component_el_avatar, {
      size: 60,
      src: "https://empty",
      onError: _ctx.errorHandler
    }, {
      default: _withCtx(() => [
        _createVNode("img", { src: "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" })
      ]),
      _: 1
    }, 8, ["onError"])
  ]))
}
  
    const democomponentExport = {
    setup() {
      const errorHandler = () => {
        return true
      }
      return {
        errorHandler
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createVNode: _createVNode, resolveComponent: _resolveComponent } = Vue

function render (_ctx, _cache) {
  const _component_el_avatar = _resolveComponent("el-avatar")

  return (_openBlock(), _createBlock("div", { class: "demo-fit" }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fits, (fit) => {
      return (_openBlock(), _createBlock("div", {
        class: "block",
        key: fit
      }, [
        _createVNode("span", { class: "title" }, _toDisplayString(fit), 1),
        _createVNode(_component_el_avatar, {
          shape: "square",
          size: 100,
          fit: fit,
          src: _ctx.url
        }, null, 8, ["fit", "src"])
      ]))
    }), 128))
  ]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      let data = reactive({
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      })
      return { ...toRefs(data) }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  